.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  border-spacing: 0;
  border-collapse: collapse;
}

.panel > .panel-collapse > .table, .panel > .table, .panel > .table-responsive > .table {
  margin-bottom: 0;
}

.panel > .table-responsive:last-child > .table:last-child, .panel > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > thead > tr > th {
  padding: 5px;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  text-align: left;
}

.table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > thead > tr > th {
  padding: 5px;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.toolbar {
  padding: 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
}