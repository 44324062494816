.BookingPage {
  h1.header {
    margin-bottom: 20px;
  }

  .toolbar{
    border-radius: 4px;
    display: flex;

    > * {
      margin-right: 10px;
    }

    .bp3-editable-text {
      margin: 5px 10px;
    }
  }

  .dl-horizontal {
    padding: 0;
    margin: 0;

    @media (min-width: 768px) {
      dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 3px;
        font-weight: bold;
      }


      dd {
        margin-left: 180px;
        margin-bottom: 3px;
      }
    }
  }

  tr.pnr-row {
    td {
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.01);
    }
  }

  .log-table {
    font-size: 11px;

    .datetime {
      white-space: nowrap;
    }
  }

  .pnr-block {
    background-color: #f5f5f5;
    padding: 15px;
    margin-bottom: 25px;
    margin-top: -15px;

    .pnr-data {
      padding: 15px;
      min-height: 50px;
      color: #FFF;
      background-color: rgb(39, 40, 34);
    }
  }
}

// These are outside of the page block (modals)
.booking-email {
  &.bp3-dialog {
    padding-bottom: 0;
    width: auto;
  }

  .bp3-dialog-body {
    margin: 5px;
  }

  textarea, iframe {
    min-height: 50vh;
    min-width: 60vw;
  }

  iframe {
    border: 1px solid #CCC;
  }
}