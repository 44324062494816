.BookingsPage {

  .bookings-table {
    margin-bottom: 20px;

    .column-names {
      th {
        padding-bottom: 0 !important;
      }
    }

    tbody {
      td.created-at {
        font-size: 11px;
      }

      td.passengers {
        font-size: 11px;
      }
    }

    tfoot {
      tr {
        td {
          background: #ececec;
          padding: 10px 12px !important;

          .pages {
            margin-top: 2px;
          }
        }
      }
    }
  }

  h1.header {
    font-weight: 600;
    font-size: 24px;
  }
}