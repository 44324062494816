.TasksPage {
  .tasks-table {
    width: 100%;

    .pages {
      text-align: center;
    }

    tfoot tr td {
      background: #ececec;
      padding: 10px 12px !important;
    }

    margin-bottom: 20px;
  }

  .task-output {
    &.bp3-dialog {
      padding-bottom: 0;
      width: auto;
    }

    .bp3-dialog-body {
      margin: 5px;
    }

    textarea {
      min-height: 50vh;
      min-width: 60vw;
      white-space: pre;
    }

  }
}