.EmailsSendPage {

  .toolbar {

  }

  h2 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .sql-block {
    button {
      margin-top: 10px;
    }
  }

  textarea {
    min-height: 100px;
  }

  .message-block {
    textarea {
      margin: 10px 0;
    }
  }

  .send-result {
    margin-top: 10px;
    font-weight: bold;
  }
}