.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
}

.container.is-fluid {
  max-width: none;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 1215px) {
  .container.is-widescreen {
    max-width: 1152px;
  }
}

@media screen and (max-width: 1407px) {
  .container.is-fullhd {
    max-width: 1344px;
  }
}

@media screen and (min-width: 1216px) {
  .container {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container {
    max-width: 1344px;
  }
}

$column-gap: 20px;

.columns {
  margin-left: -$column-gap;
  margin-right: -$column-gap;
  margin-top: -$column-gap;
  display: flex;
  flex-wrap: wrap;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: $column-gap;

  &.column-2x {
    flex-grow: 2;
  }
}

