@import "~normalize.css/normalize.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-section {
  padding-top: 2em;
}

//Container
//@media screen and (min-width: 1216px) {
//  .container {
//    max-width: 1152px;
//  }
//}
//
//@media screen and (min-width: 1024px) {
//  .container {
//    max-width: 960px;
//  }
//}
//
//.container {
//  flex-grow: 1;
//  margin: 0 auto;
//  position: relative;
//  width: auto;
//}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.bp3-breadcrumbs {
  padding: 8px 15px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;

  .bp3-breadcrumb, .bp3-breadcrumb-current {
    font-size: 14px;
  }
}

@import "./grid.scss";
@import "./panels.scss";
@import "./table.scss";

//@import "~bulma/css/bulma.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";